<template>
  <table class="simple-table w-100">
    <thead>
      <tr>
        <th width="278px" class="caption font-weight-medium">Camera Name</th>
        <th width="230px" class="caption font-weight-medium">Start Date</th>
        <th class="caption font-weight-medium">End Date</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(value, index) in footages" :key="index">
        <td>{{ value.cameraName }}</td>
        <td>{{ value.startDate }}</td>
        <td>{{ value.endDate }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "HddFootages",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    footages() {
      return this.item.footages
    },
  },
}
</script>

<style lang="scss">
.simple-table {
  border-collapse: collapse;
  th {
    text-align: left;
  }
}
</style>
