<template>
  <div>
    <v-select
      v-model="selectedNoteType"
      :items="noteTypes"
      return-object
      item-text="label"
      label="Select a public note type"
    />
    <!-- Extra fields -->
    <div v-if="selectedNoteType">
      <v-menu
        v-if="selectedNoteType.extraField === 'date'"
        v-model="datePickerMenu"
        :close-on-content-click="false"
        min-width="auto"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            prepend-icon="fa-calendar-alt"
            label="Enter a Visit Date"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="date"
          :min="minDate"
          color="#1976d2"
          @input="datePickerMenu = false"
        />
      </v-menu>
      <div v-if="selectedNoteType.extraField === 'interval'">
        <div class="d-flex">
          <v-select
            v-model="startHour"
            :items="hours"
            label="Schedule start hour"
            class="mr-2"
          />
          <v-select
            v-model="endHour"
            :items="hours"
            label="Schedule end hour"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CameraPublicNoteEditor",
  data() {
    return {
      date: null,
      datePickerMenu: false,
      startHour: null,
      endHour: null,
      minDate: new Date().toISOString(),
      selectedNoteType: null,
      noteTypes: [
        {
          label: "Camera Offline - Undergoing Diagnostics",
        },
        {
          label: "Camera Offline - Site check in progress",
        },
        {
          label: "Camera Offline - Site Contacted to Restore Power",
        },
        {
          label: "Camera Offline - Awaiting Relocation",
        },
        {
          label: "Camera Offline - Maintenance visit scheduled On XX/XX/XXXX",
          text: "Camera Offline - Maintenance visit scheduled",
          extraField: "date",
        },
        {
          label: "Camera Offline - Scheduled to Operate From XX to XX",
          text: "Camera Offline - Scheduled to Operate",
          extraField: "interval",
        },
      ],
    }
  },
  computed: {
    hours() {
      return new Array(24).fill().map((_, h) => (h < 10 ? `0${h}` : `${h}`))
    },
    formattedNote() {
      if (!this.selectedNoteType) {
        return ""
      }

      if (this.selectedNoteType.extraField === "date") {
        const formattedDate = this.$moment(this.date).format("DD/MM/YYYY")

        return this.date
          ? `${this.selectedNoteType.text} on ${formattedDate}`
          : ""
      }

      if (this.selectedNoteType.extraField === "interval") {
        return this.startHour && this.endHour
          ? `${this.selectedNoteType.text} From ${this.startHour}:00 To ${this.endHour}:00`
          : ""
      }

      return this.selectedNoteType.label
    },
  },
  watch: {
    formattedNote() {
      this.$emit("change", this.formattedNote)
    },
  },
}
</script>
