<!-- eslint-disable prettier/prettier -->
<template>
  <ERow no-gutters>
    <ECol
      v-if="loading"
      cols="12"
      class="fill-height ma-0"
      align="center"
      justify="center"
    >
      <EvercamLoadingAnimation size="FourXl" class="my-3" />
    </ECol>
    <ECol v-else cols="12">
      <ETimeline
        class="e-w-full"
        :min-date="$moment(from).format()"
        :max-date="$moment(to).format()"
        :max-domain-padding="0.25"
        :dark="$vuetify.theme.dark"
        :events-groups="eventsGroups"
        :markers="[cameraInstalledMarker]"
        :forbidden-intervals="forbiddenIntervals"
        forbidden-interval-color="#000"
        :bar-height="20"
        :bar-y-padding="18"
        :show-labels="true"
        pan-on-date-click
      >
        <template #eventTooltip="{ event, active }">
          <div
            v-if="event && active"
            class="e-rounded-md e-border e-p-3 e-m-2 e-text-black e-w-80 e-bg-slate-50"
            :style="{ border: '1px solid lightgrey', background: '#f2f2f2' }"
          >
            <div class="e-flex e-justify-start e-items-center e-gap-4">
              <div>
                <span class="e-mr-2 e-font-medium">From: &ThinSpace;</span>
                <span class="e-text-xs">
                  {{
                    $moment(event.startDate).format("YYYY-MM-DD HH:mm:ss")
                  }}</span
                >
              </div>
            </div>

            <div class="e-flex e-justify-start e-items-center e-gap-4">
              <div>
                <span class="e-mr-2 e-font-medium">To: &ThinSpace;</span>
                <span class="e-text-xs">
                  {{
                    $moment(event.endDate).format("YYYY-MM-DD HH:mm:ss")
                  }}</span
                >
              </div>
            </div>

            <div class="e-flex e-justify-start e-items-center e-gap-4">
              <div>
                <span class="e-mr-2 e-font-medium">status: &ThinSpace;</span>
                <span class="e-text-xs e-font-semibold">
                  {{ event.status.toUpperCase() }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </ETimeline>
    </ECol>
  </ERow>
</template>

<script>
import { CameraStatus } from "@evercam/shared/types/camera"
import { ExNvrFootageAvailableStatus } from "@evercam/shared/types/streaming"
import { NvrModel } from "@evercam/shared/types"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { ExNvrApi } from "@evercam/shared/api/exNvrApi"
import { useAccountStore } from "@/stores/account"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default {
  name: "CameraStatusTimeline",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    exid: {
      type: String,
      required: true,
    },
    camera: {
      type: [String, Object],
      required: true,
    },
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      eventsGroups: {},
      loading: false,
      exNvrGroup: {},
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useAccountStore),
    cameraInstalledMarker() {
      return {
        timestamp: this.$moment(
          this.cameraDialogStore?.camera?.createdAt
        ).format(),
        label: "Camera installed",
        color: "#c3d8f5",
        textColor: "",
        id: "createdAt",
      }
    },
    forbiddenIntervals() {
      return [
        {
          startDate: -Infinity,
          endDate: new Date(this.from).toISOString(),
        },
        {
          startDate: new Date(this.to).toISOString(),
          endDate: Infinity,
        },
      ]
    },
  },
  watch: {
    exid: {
      async handler(exid, oldExid) {
        if (exid === oldExid) {
          return
        }
        await this.getDataSet()
      },
      immediate: true,
    },
    from: {
      async handler(from, oldFrom) {
        if (from === oldFrom) {
          return
        }
        await this.getDataSet()
      },
    },
    "cameraDialogStore.isNvrHttpPortOpen": {
      immediate: true,
      handler() {
        if (!this.cameraDialogStore.isCheckingNvrHttpPortStatus) {
          this.getDataSet()
        }
      },
    },
    "cameraDialogStore.isCheckingNvrHttpPortStatus": {
      immediate: true,
      handler(value) {
        if (!value) {
          this.getDataSet()
        }
      },
    },
    "cameraDialogStore.isExNvrDeviceSelected": {
      immediate: true,
      handler() {
        if (!this.cameraDialogStore.isCheckingNvrHttpPortStatus) {
          this.getDataSet()
        }
      },
    },
    async "cameraDialogStore.cameraUpdatedAt"() {
      await this.getDataSet()
    },
  },
  methods: {
    getColorPerStatus(status) {
      const colors = {
        [CameraStatus.Decommissioned]: "#115e59",
        [CameraStatus.Offline]: "#dc2626",
        [CameraStatus.Online]: "#15803d",
        [CameraStatus.OfflineScheduled]: "#facc15",
        [CameraStatus.Waiting]: "#f97316",
        [CameraStatus.WaitingForSiteVisit]: "#a3a3a3",
        [CameraStatus.OnHold]: "#f43f5e",
        [CameraStatus.UnderMaintenance]: "#f87171",
        [ExNvrFootageAvailableStatus.Active]: "#15803d",
        [ExNvrFootageAvailableStatus.NotActive]: "#15803d",
        [ExNvrFootageAvailableStatus.NotAvailable]: "#dc2626",
      }

      return colors[status]
    },
    async checkExNVRFootageAvailability() {
      const deviceId = this.camera.nvrDeviceId
      const apiUrl = `https://${this.camera?.nvrHost}:${this.camera?.nvrHttpPort}`
      if (
        !this.cameraDialogStore.exNvrToken &&
        this.cameraDialogStore.isNvrHttpPortOpen
      ) {
        await this.cameraDialogStore.getExNVRToken({
          cameraExid: this.camera.exid,
          token: this.accountStore.token,
        })
      }
      if (
        !deviceId ||
        !this.cameraDialogStore.exNvrToken ||
        !this.camera?.nvrHost ||
        !this.camera?.nvrHttpPort ||
        !this.cameraDialogStore.isExNvrDeviceSelected
      ) {
        return
      }
      try {
        this.exNvrAvailableRecordingsIntervals =
          await ExNvrApi.devices.getAvailableRecordings({
            deviceId,
            apiUrl,
            token: this.cameraDialogStore.exNvrToken,
          })
        const exNvrRecordingsIntervals = []
        let previousEnd = null

        this.exNvrAvailableRecordingsIntervals.forEach((interval, index) => {
          if (index === 0) {
            exNvrRecordingsIntervals.push(interval)
            previousEnd = interval.endDate
          } else {
            const diff = new Date(interval.startDate) - new Date(previousEnd)
            if (diff > 0) {
              const intervalGap = {
                status: ExNvrFootageAvailableStatus.NotAvailable,
                startDate: previousEnd,
                endDate: interval.startDate,
              }
              exNvrRecordingsIntervals.push(intervalGap)
            }
            exNvrRecordingsIntervals.push(interval)
            previousEnd = interval.endDate
          }
        })

        this.allExNvrRecordingsIntervals = exNvrRecordingsIntervals
        this.exNvrGroup = {
          [`${this.exid}-exnvr`]: {
            label: "Ex NVR Footage Availability",
            color: "rgba(81,145,64,0.96)",
            events: this.allExNvrRecordingsIntervals.map((el) => {
              let availability
              if (el.status === ExNvrFootageAvailableStatus.NotAvailable) {
                availability = ExNvrFootageAvailableStatus.NotAvailable
              } else {
                availability = el.active
                  ? ExNvrFootageAvailableStatus.Active
                  : ExNvrFootageAvailableStatus.NotActive
              }

              return {
                startDate: new Date(el.startDate).getTime(),
                endDate: new Date(el.endDate).getTime(),
                color: this.getColorPerStatus(availability),
                status: availability,
              }
            }),
          },
        }
      } catch (error) {
        this.$notifications.error({
          text: "Could not check the exnvr footage availability",
          error,
        })
      }
    },
    async getDataSet() {
      this.loading = true
      if (this.cameraDialogStore.isCheckingNvrHttpPortStatus) {
        return
      }
      try {
        let response = await EvercamApi.cameras.getCameraStatusLogs(this.exid, {
          params: {
            from: `${this.$moment(this.from).format(
              "YYYY-MM-DDTHH:mm:ss"
            )}.000Z`,
            to: `${this.$moment(this.to).format("YYYY-MM-DDTHH:mm:ss")}.000Z`,
          },
        })
        if (
          this.camera.nvrModel === NvrModel.ExNvr &&
          !this.cameraDialogStore.isCheckingNvrHttpPortStatus &&
          this.cameraDialogStore.isNvrHttpPortOpen
        ) {
          await this.checkExNVRFootageAvailability()
        }
        this.eventsGroups = {
          [this.exid]: {
            label: "Camera Status",
            color: "rgba(81,145,64,0.96)",
            events: response.map((el) => {
              return {
                startDate: new Date(el.start).toISOString(),
                endDate: new Date(el.end).toISOString(),
                color: this.getColorPerStatus(el.state),
                status: el.state,
              }
            }),
          },
          ...this.exNvrGroup,
        }
      } catch (error) {
        this.$notifications.error({
          text: `Could not load the statuses dataset of ${this.exid} camera`,
          error,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
