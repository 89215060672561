<template>
  <td>
    <pre v-if="typeof value === 'object'">{{
      JSON.stringify(value, null, 2)
    }}</pre>
    <span v-else>
      <EReadMore :content="value" :max-length="60" />
    </span>
  </td>
</template>

<script>
export default {
  name: "CameraLogDiffTableCell",
  props: {
    value: {
      type: [String, Object, Array],
      default: "",
    },
  },
}
</script>
