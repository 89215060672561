<template>
  <ERow class="hdds-timeline mt-8 mb-2" justify="center" align="stretch">
    <ECol cols="12" class="py-0">
      <h2>{{ title }}</h2>
      <ETimeline
        v-if="eventsGroups"
        class="e-w-full"
        :dark="$vuetify.theme.dark"
        :events-groups="eventsGroups"
        :selected-timestamp="selectedTimestamp"
        :start-date="startDate"
        :end-date="endDate"
        :bar-height="20"
        :bar-y-padding="18"
        @date-clicked="(d) => (selectedTimestamp = d)"
      >
        <template #eventTooltip="{ event, active }">
          <div
            v-if="event && active"
            class="e-rounded-md e-border e-p-3 e-m-2 e-text-black e-w-80"
            :style="{ background: colorEven, border: '1px solid lightgrey' }"
          >
            <div class="e-flex e-justify-start e-items-center e-gap-4">
              <span class="e-mr-2 e-font-medium"
                >Serial Number: &ThinSpace;</span
              >
              <span class="e-text-xs">
                <v-chip x-small :color="hddsColors[event.id]" label>
                  {{ event.serialNumber }}
                </v-chip>
              </span>
            </div>

            <div class="e-flex e-justify-start e-items-center e-gap-4">
              <div>
                <span class="e-mr-2 e-font-medium">From: &ThinSpace;</span>
                <span class="e-text-xs">
                  {{ $moment(event.startDate).format() }}</span
                >
              </div>
            </div>

            <div class="e-flex e-justify-start e-items-center e-gap-4">
              <div>
                <span class="e-mr-2 e-font-medium">To: &ThinSpace;</span>
                <span class="e-text-xs">
                  {{ $moment(event.endDate).format() }}</span
                >
              </div>
            </div>

            <div class="e-flex e-justify-start e-items-center e-gap-4">
              <div>
                <span class="e-mr-2 e-font-medium">Location: &ThinSpace;</span>
                <span class="e-text-xs">
                  {{ event.location }}
                </span>
              </div>
            </div>

            <div class="e-flex e-justify-start e-items-center e-gap-4">
              <span class="e-mr-2 e-font-medium">size: &ThinSpace;</span>
              <span class="e-text-xs">
                {{ event.size }}
              </span>
            </div>

            <div class="e-flex e-justify-start e-items-center e-gap-4">
              <span class="e-mr-2 e-font-medium">Status: &ThinSpace;</span>
              <span class="e-text-xs">
                {{ event.hddStatus }}
              </span>
            </div>
          </div>
        </template>
      </ETimeline>
    </ECol>
  </ERow>
</template>

<script lang="ts">
import { Hdd } from "@evercam/shared/types"
import Vue, { PropType } from "vue"

export default Vue.extend({
  name: "HddPerCamera",
  props: {
    hddsList: {
      type: Array as PropType<Hdd[]>,
      default: () => [],
    },
    hddsColors: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      hddsPerCamera: [],
      eventsGroups: null,
      selectedTimestamp: undefined,
      cameras: [],
      title: "Footages (Footage = continuous video with < 72hr gap)",
      colorEven: "#e0e0e0",
      colorOdd: "#fafafa",
    }
  },
  computed: {
    minTimestamp() {
      const defaultMinDate = new Date(
        new Date().getTime() - 86_400_000
      ).getTime()

      return this.hddsPerCamera.reduce((minTimestamp, item) => {
        const minStartDate = item.hdds.reduce((minStartDate, hdd) => {
          const startDate = new Date(hdd.startDate).getTime()

          return Math.min(startDate, minStartDate)
        }, defaultMinDate)

        return Math.min(minStartDate, minTimestamp)
      }, defaultMinDate)
    },
    maxTimestamp() {
      const defaultMaxDate = new Date().getTime()

      return this.hddsPerCamera.reduce((maxTimestamp, item) => {
        const maxEndDate = item.hdds.reduce((maxEndDate, hdd) => {
          const endDate = new Date(hdd.endDate).getTime()

          return Math.min(endDate, maxEndDate)
        }, defaultMaxDate)

        return Math.min(maxEndDate, maxTimestamp)
      }, defaultMaxDate)
    },
    totalInterval() {
      return this.maxTimestamp - this.minTimestamp
    },
    startDate() {
      const padding = this.totalInterval / 4

      return this.$moment(this.minTimestamp - padding)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]")
    },
    endDate() {
      const padding = this.totalInterval / 12

      return this.$moment(this.maxTimestamp + padding)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]")
    },
  },
  watch: {
    hddsList() {
      this.getProjectHdds()
    },
  },
  mounted() {
    this.getProjectHdds()
  },
  methods: {
    getProjectHdds() {
      try {
        this.cameras = [
          ...new Set(
            this.hddsList
              .map((item) =>
                item.footages.map((item) => ({
                  exid: item.cameraExid,
                  name: item.cameraName,
                }))
              )
              .flat()
          ),
        ]
        this.hddsPerCamera = this.cameras.map((camera) => {
          let list = []
          this.hddsList.forEach((hdd) => {
            hdd.footages.forEach((footage) => {
              if (footage.cameraExid === camera.exid) {
                list.push({
                  serialNumber: hdd.serialNumber,
                  id: hdd.id,
                  location: hdd.location,
                  size: hdd.size,
                  hddStatus: hdd.hddStatus,
                  startDate: new Date(footage.startDate).getTime(),
                  endDate: !footage.endDate
                    ? new Date().getTime()
                    : new Date(footage.endDate).getTime(),
                })
              }
            })
          })

          return {
            cameraName: camera.name,
            cameraExid: camera.exid,
            hdds: list,
          }
        })

        this.hddsPerCamera.forEach((item, index) => {
          this.eventsGroups = {
            ...this.eventsGroups,
            [item.cameraExid]: {
              color: index % 2 === 0 ? this.colorEven : this.colorOdd,
              bgColor: index % 2 === 0 ? "#737373" : this.colorOdd,
              label: item.cameraName,
              events: item.hdds.map((hdd) => ({
                color: this.hddsColors[hdd.id],
                startDate: hdd.startDate,
                endDate: hdd.endDate,
                serialNumber: hdd.serialNumber,
                id: hdd.id,
                location: hdd.location,
                size: hdd.size,
                hddStatus: hdd.hddStatus,
                text: hdd.serialNumber,
              })),
              chartType: "bars",
            },
          }
        })
      } catch (error) {
        this.$notifications.error({
          text: "Failed to fetch HDDs ",
          error,
        })
      }
    },
  },
})
</script>

<style lang="scss">
.hdds-timeline {
  .e-timeline {
    border: 1px solid rgba(209, 213, 219);
    .event-rect {
      stroke: #464646 !important;
      stroke-width: 0.4;
    }
  }
}
</style>
