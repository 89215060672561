<template>
  <ERow justify="center" align="stretch">
    <ECol cols="12" class="py-0">
      <v-data-table
        ref="companiesHddsTable"
        :headers="headers"
        :items="hddsList"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :options.sync="options"
        :single-expand="true"
        :expanded.sync="expanded"
        class="admin-data-table"
        must-sort
        dense
        show-expand
        :loading="isLoading"
        calculate-widths
        :mobile-breakpoint="0"
        item-key="id"
        loading-text="Please wait..."
        fixed-header
        :footer-props="{
          'items-per-page-options': [50, 100, 200],
        }"
        :hide-default-footer="hddsList.length <= 50"
      >
        <template #top>
          <v-container fluid class="py-0">
            <ERow>
              <ECol cols="6" class="pl-0 pb-0 text-h6"> HDDs </ECol>
              <v-spacer />
              <ECol cols="1" class="mb-1 pb-0 text-right">
                <CopyTableToClipboard
                  :headers="headers"
                  :items="filteredItems"
                />
              </ECol>
            </ERow>
          </v-container>
        </template>
        <template #item.data-table-expand="{ item, isExpanded, expand }">
          <v-icon
            v-if="item.canExpand && !isExpanded"
            x-small
            color="primary"
            @click="expand(true)"
          >
            fa-plus
          </v-icon>
          <v-icon
            v-if="item.canExpand && isExpanded"
            role="button"
            x-small
            color="primary"
            @click="expand(false)"
          >
            fa-minus
          </v-icon>
        </template>
        <template #expanded-item="{ item }">
          <td :colspan="8" class="pl-0 pr-0">
            <HddFootages :item="item" />
          </td>
        </template>
        <template #item.serialNumber="{ item }">
          <v-chip small :color="hddsColors[item.id]" label class="hdd-chip">
            {{ item.serialNumber }}
          </v-chip>
        </template>
      </v-data-table>
    </ECol>
  </ERow>
</template>

<script lang="ts">
import hddsHeaders from "@/components/hdds/hddsTableHeaders"
import HddFootages from "@/components/hdds/HddFootages"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import Vue, { PropType } from "vue"
import { Hdd } from "@evercam/shared/types"

export default Vue.extend({
  components: {
    CopyTableToClipboard,
    HddFootages,
  },
  props: {
    hddsList: {
      type: Array as PropType<Hdd[]>,
      default: () => [],
    },
    hddsColors: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHeight: null,
      selected: [],
      expanded: [],
      headers: hddsHeaders,
      options: {},
      sortBy: "serialNumber",
      sortDesc: true,
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = this.$refs.companiesHddsTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.hddsList
    },
  },
})
</script>

<style>
.hdd-chip {
  height: 17px !important;
  padding: 0 5px;
}
</style>
