<template>
  <v-container fluid class="logs pa-0 height-auto">
    <ERow no-gutters class="mt-6">
      <CameraStatusTimeline
        :exid="camera.exid"
        :camera="camera"
        :from="camera.createdAt"
        :to="new Date().toISOString()"
      />
    </ERow>
    <!-- Logs table -->
    <ERow no-gutters>
      <ECol cols="12">
        <v-card :loading="loading" flat>
          <ERow class="mx-0" align="center">
            <ECol cols="auto" xs="12" md="12" lg="12" class="py-0 pl-2">
              <v-card class="d-flex flex-row" color="lighten-2" flat tile>
                <v-card
                  class="pa-1 subtitle-2 grey--text text--darken-2 pl-2"
                  flat
                  tile
                >
                  From:
                </v-card>
                <v-card class="pa-1" flat tile>
                  <VueCtkDateTimePicker
                    v-model="fromDatetime"
                    hint="From"
                    format="YYYY-MM-DDTHH:mm:ss"
                    formatted="DD/MM/YYYY HH:mm"
                    button-color="#1976d2"
                    color="#1976d2"
                    no-button-now
                    no-shortcuts
                    no-header
                  >
                    <v-text-field class="caption mt-0 pt-0 border-bottom" />
                  </VueCtkDateTimePicker>
                </v-card>

                <v-card
                  class="pa-1 ml-2 subtitle-2 grey--text text--darken-2"
                  flat
                  tile
                >
                  To:
                </v-card>
                <v-card class="pa-1" flat tile>
                  <VueCtkDateTimePicker
                    v-model="toDatetime"
                    format="YYYY-MM-DDTHH:mm:ss"
                    formatted="DD/MM/YYYY HH:mm"
                    button-color="#1976d2"
                    color="#1976d2"
                    no-button-now
                    no-shortcuts
                    no-header
                    no-label
                  >
                    <v-text-field class="caption mt-0 pt-0 border-bottom" />
                  </VueCtkDateTimePicker>
                </v-card>
              </v-card>
              <v-expand-transition>
                <ERow class="mx-0" align="center">
                  <ECol cols="12" class="pt-1 pb-0 pl-0">
                    <CameraLogsFilters v-model="filterParams" />
                    <br />
                  </ECol>
                </ERow>
              </v-expand-transition>
            </ECol>
            <ECol cols="auto" xs="12" md="12" lg="12" class="pt-0 pb-1 mb-6">
              <v-btn color="primary" small @click="openNoteDialog">
                Add private Note
              </v-btn>
              <v-btn color="primary" small @click="openPublicNoteDialog">
                Add Public Note
              </v-btn>
            </ECol>
          </ERow>

          <CameraNoteDialog
            v-model="noteDialog"
            :camera="camera"
            :note-type="noteType"
            :is-public-note="isPublicNote"
            @success="onNoteAdded"
          />
        </v-card>
      </ECol>
    </ERow>
    <ERow no-gutters>
      <ECol cols="12" class="px-0">
        <v-data-table
          dense
          :headers="headers"
          :items="indexedLogs"
          :single-expand="true"
          :expanded.sync="expanded"
          :items-per-page="15"
          sort-desc
          item-key="id"
          class="admin-data-table"
          show-expand
        >
          <template #item.data-table-expand="{ item, isExpanded, expand }">
            <v-icon
              v-if="item.canExpand"
              x-small
              color="primary"
              @click="expand(!isExpanded)"
            >
              {{ isExpanded ? "fa-minus" : "fa-plus" }}
            </v-icon>
          </template>

          <template #item.insertedAt="{ item }">
            <div class="caption">
              {{ formatDate(item.insertedAt, "ddd, MMM DD, YYYY, HH:mm:ss") }}
            </div>
          </template>

          <template #item.action="{ item }">
            <div class="caption">
              <div v-if="item.action === logTypes.ONLINE">
                <span class="green--text">Camera came online</span>
                {{
                  getFormattedDatesDiff(
                    item.details.online,
                    item.details.offline
                  )
                }}
              </div>
              <div v-else-if="item.action === logTypes.OFFLINE">
                <span class="red--text">Camera went offline</span>
                {{ getOfflineCause(item) }}
              </div>
              <div v-else-if="item.action === logTypes.SMS">
                <span
                  v-if="
                    item.details.status == 'delivered' ||
                    item.details.status == 'Received'
                  "
                  class="green--text"
                  >{{ item.details.status }}:</span
                >
                <span v-else class="red--text">{{ item.details.status }}:</span>
                <EReadMore :content="item.details.message" :max-length="60" />
              </div>
              <div
                v-else-if="
                  [
                    logTypes.ON_HOLD,
                    logTypes.WAITING,
                    logTypes.DECOMMISSIONED,
                    logTypes.UNDER_MAINTENANCE,
                    logTypes.WAITING_FOR_SITE_VISIT,
                  ].includes(item.action)
                "
              >
                <span class="red--text">
                  <EReadMore
                    :content="`Camera status changed from ${item.details.settings.old.status} to ${item.details.settings.new.status}`"
                    :max-length="60"
                  />
                </span>
              </div>
              <div
                v-else-if="
                  [logTypes.CUSTOM, logTypes.PUBLIC_NOTE].includes(item.action)
                "
                class="custom-note-css"
                :style="getNoteTypeStyle(item.action)"
              >
                {{ item.action === logTypes.CUSTOM ? "Private" : "Public" }}
                Note:
                {{ item.details.customMessage }}
              </div>
              <div v-else-if="item.action === logTypes.SIM">
                {{ item.details.customMessage }}
              </div>
              <div v-else>
                <EReadMore :content="getEvent(item)" :max-length="60" />
              </div>
            </div>
          </template>

          <template #item.who="{ item }">
            <div class="caption">
              {{ item.who }}
            </div>
          </template>

          <template #expanded-item="{ item }">
            <td :colspan="4" class="pl-0 pr-0">
              <CameraLogDiff :item="item" />
            </td>
          </template>
        </v-data-table>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import { debounce } from "@evercam/shared/utils"
import CameraNoteDialog from "@/components/offlineCameras/CameraNoteDialog"
import CameraLogDiff from "@/components/offlineCameras/CameraLogDiff"
import CameraLogsFilters from "@/components/offlineCameras/CameraLogsFilters"
import CameraStatusTimeline from "@/components/offlineCameras/CameraStatusTimeline"
import ErrorsCauseDescriptions from "@evercam/shared/constants/errorCause"
import LOG_TYPES from "@evercam/shared/constants/logTypes"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default {
  name: "CameraLogs",
  components: {
    VueCtkDateTimePicker,
    CameraNoteDialog,
    CameraLogDiff,
    CameraLogsFilters,
    CameraStatusTimeline,
  },
  props: {
    camera: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      success: [],
      loading: false,
      noteDialog: false,
      isPublicNote: false,
      noteType: "custom",
      tail: "",
      toDatetime: this.$moment().format(),
      fromDatetime: this.$moment().subtract({ days: 15 }).format(),
      filterParams: [],
      expanded: [],
      headers: [
        {
          text: "Date & Time (Camera Time)",
          value: "insertedAt",
          class: "caption font-weight-medium",
          width: 200,
        },
        {
          text: "User/Client",
          value: "who",
          class: "caption font-weight-medium",
          width: 180,
        },
        { text: "Event", value: "action", class: "caption font-weight-medium" },
      ],
      logs: [],
      causeDescriptions: ErrorsCauseDescriptions,
      logTypes: LOG_TYPES,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
    indexedLogs() {
      return this.logs.map((log, i) => ({ id: i, ...log }))
    },
    datesFilter() {
      const formatDate = (date) =>
        `${this.$moment(date).format("YYYY-MM-DDTHH:mm:ss")}.000Z`
      if (
        this.$moment(this.fromDatetime).isAfter(this.$moment(this.toDatetime))
      ) {
        return {
          from: formatDate(this.toDatetime),
          to: formatDate(this.fromDatetime),
        }
      }

      return {
        from: formatDate(this.fromDatetime),
        to: formatDate(this.toDatetime),
      }
    },
  },
  watch: {
    filterParams: "debouncedSearch",
    fromDatetime: "debouncedSearch",
    toDatetime: "debouncedSearch",
    camera: {
      immediate: true,
      handler(val, oldVal) {
        if (this.loading || val?.exid === oldVal?.exid) {
          return
        }
        this.filterLog()
      },
    },
    "cameraDialogStore.cameraUpdatedAt"() {
      this.$setTimeout(this.filterLog)
    },
  },
  methods: {
    debouncedSearch: debounce(function () {
      this.filterLog()
    }),
    getNoteTypeStyle(action) {
      return action === this.logTypes.CUSTOM
        ? "background-color: #FFFF8D"
        : "background-color: #C8E6C9"
    },
    getEvent(log) {
      const ip =
        log.userDetails && log.userDetails.ip
          ? `, ip: ${log.userDetails.ip}`
          : ""

      const logTypes = [
        {
          types: [this.logTypes.CREATE_ARCHIVE],
          text: () => `${log.action} ${log.details.name || ""} ${ip}`,
        },
        {
          types: [this.logTypes.VH_STATUS],
          text: () => "Virtual Host auto-enabled by system",
        },
        {
          types: [this.logTypes.SHARE_REQUESTS],
          text: () => `Sent camera share request to ${log.details.with}${ip}`,
        },
        {
          types: [this.logTypes.DELETE_SHARE_REQUESTS],
          text: () =>
            `Deleted camera share request for ${log.details.with}${ip}`,
        },
        {
          types: [this.logTypes.SHARE],
          text: () => `Camera shared with ${log.details.with}${ip}`,
        },
        {
          types: [this.logTypes.DELETE_SHARE],
          text: () => `Stopped camera sharing with ${log.details.with}${ip}`,
        },
        {
          types: [this.logTypes.UPDATE_SHARE],
          text: () =>
            `Updated camera share rights for ${log.details.with}${ip}`,
        },
        {
          types: [this.logTypes.CR_CREATED],
          text: () => `Cloud recordings created${ip}`,
        },
        {
          types: [this.logTypes.CR_UPDATED],
          text: () => `Coud recordings updated${ip}`,
        },
        {
          types: [this.logTypes.LINK_SIM_WITH_ROUTER],
          text: () =>
            `Linked SIM ${log.details.router.simNumber} with router ${log.details.router.serialNumber}`,
        },
        {
          types: [this.logTypes.UNLINK_SIM_FROM_ROUTER],
          text: () =>
            `Unlinked SIM ${log.details.router.simNumber} from router ${log.details.router.serialNumber}`,
        },
        {
          types: [this.logTypes.LINK_CAMERA_WITH_ROUTER],
          text: () =>
            `Linked camera ${log.details.router.cameraName} with router ${log.details.router.serialNumber}`,
        },
        {
          types: [this.logTypes.UNLINK_CAMERA_FROM_ROUTER],
          text: () =>
            `Unlinked camera ${log.details.router.cameraName} from router ${log.details.router.serialNumber}`,
        },
        {
          types: [this.logTypes.DEVICE_SETTINGS],
          text: () => {
            if (log.entity === "nvr") {
              return "NVR device settings."
            }

            return log.details.message
          },
        },
        {
          types: [
            this.logTypes.CREATE,
            this.logTypes.UPDATE,
            this.logTypes.DELETE,
          ],
          text: () => {
            if (log.entity === "cameras") {
              return `Camera ${log.action}d`
            } else if (log.entity === "layers") {
              return `Layer ${log.action}d`
            }

            return `Router ${log.action}d`
          },
        },
      ]

      return (
        logTypes?.find(({ types }) => types.includes(log.action))?.text() ||
        log.action
      )
    },
    getOfflineCause(log) {
      return this.causeDescriptions[log?.details?.reason] || ""
    },
    async filterLog() {
      try {
        if (this.filterParams.length > 0 && this.camera?.exid) {
          this.loading = true
          let payload = {
            params: {
              limit: 10000,
              page: 0,
              sort: "inserted_at|desc",
              action: this.filterParams.join().split(","),
              ...this.datesFilter,
            },
          }
          const logs = await AdminApi.logs.getCameraLogs(
            this.camera.exid,
            payload
          )

          let logsList = []
          let latestOnlineLogIndex = -1
          logs.items.slice(-1000).forEach((log, index) => {
            if (log.action === this.logTypes.ONLINE) {
              latestOnlineLogIndex = index
            }

            if (
              log.action === this.logTypes.OFFLINE &&
              latestOnlineLogIndex > -1
            ) {
              logsList[latestOnlineLogIndex].details = {
                online: logsList[latestOnlineLogIndex].insertedAt,
                offline: log.insertedAt,
              }
              latestOnlineLogIndex = -1
            }

            logsList.push({
              action: log.action,
              insertedAt: log.insertedAt,
              details: log.details,
              userDetails: log.userDetails,
              entity: log.entity,
              who: log.who === "" ? "System" : log.who,
              canExpand: [
                this.logTypes.UPDATE,
                this.logTypes.CR_UPDATED,
                this.logTypes.CR_CREATED,
                this.logTypes.DEVICE_SETTINGS,
              ].includes(log.action),
            })
          })

          this.logs = logsList
        } else {
          this.logs = []
        }
      } catch (error) {
        this.$notifications.error({
          text: "Logs could not be loaded!",
          error,
        })
      } finally {
        this.loading = false
      }
    },
    openNoteDialog() {
      this.noteType = "custom"
      this.noteDialog = true
      this.isPublicNote = false
    },
    openPublicNoteDialog() {
      this.noteType = "public_note"
      this.noteDialog = true
      this.isPublicNote = true
    },
    onNoteAdded() {
      this.$emit("refresh-last-notes")
      this.filterLog()
    },
  },
}
</script>
