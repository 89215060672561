export default [
  {
    value: "location",
    text: "Location",
    width: 50,
    visible: true,
  },
  {
    value: "serialNumber",
    text: "Serial #",
    width: 50,
    visible: true,
  },
  {
    value: "hddStatus",
    text: "Status",
    width: 50,
    visible: true,
  },
  {
    value: "shelfBlock",
    text: "Shelf Block",
    width: 50,
    visible: true,
  },
  {
    value: "type",
    text: "Type",
    width: 50,
    visible: true,
  },
  {
    value: "size",
    text: "Size",
    width: 50,
    align: "center",
    visible: true,
  },
  {
    value: "format",
    text: "Format",
    visible: true,
    width: 50,
  },

  {
    value: "reportedFreeSpace",
    text: "Free Space",
    visible: true,
    width: 50,
  },
  {
    value: "reportedProperty",
    text: "Property",
    visible: true,
    width: 50,
  },
  {
    value: "reportedPath",
    text: "Path",
    visible: true,
    width: 50,
  },
  {
    value: "reportedHddType",
    text: "Hdd Type",
    visible: true,
    width: 50,
  },
  {
    value: "errorStatus",
    text: "Error Status",
    visible: true,
    width: 50,
  },
]
